<template>
  <div class="s-team">
    <div class="s-team__container v2-container">
      <div class="s-team__left">
        <h2 class="s-team__title title-secondary">
          Командные проекты
        </h2>

        <p class="s-team__tx text-main">
          Каждый триместр наставник объединяет учеников в&nbsp;мини-группы
          по&nbsp;5&nbsp;человек для&nbsp;создания проекта и&nbsp;распределяет роли.
          Ребята совместно разрабатывают 3D модели спутника, готовят бизнес-план
          завода и&nbsp;запускают стартапы в&nbsp;игровой форме
        </p>
      </div>

      <div class="s-team__rigth">
        <div class="s-team__i-wrap">
          <img
            loading="lazy"
            src="/v2/school/team/circle.svg"
            alt="circle"
            class="s-team__circle"
          >

          <div class="s-team__card">
            <div class="s-team__name">
              Ментальная карта стартапа в Miro
            </div>

            <img
              loading="lazy"
              src="/v2/school/team/miro.webp"
              alt="miro"
              class="s-team__img"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchoolTeamProjects',
};
</script>

<style lang="scss" scoped>
.s-team {
  &__container {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    max-width: 1272px;

    @media only screen and (max-width: $school-desktop) {
      flex-direction: column;
      gap: 52px;
    }
  }

  &__left {
    padding-top: 66px;

    max-width: 515px;
    width: 100%;

    @media only screen and (max-width: $school-desktop) {
      margin: 0 auto;
      padding-top: 0;
    }
  }

  &__title {
    margin-bottom: 20px;

    letter-spacing: -1.35px;

    @media only screen and (max-width: $school-desktop) {
      text-align: center;
    }

    @include media-down(tablet) {
      margin-bottom: 12px;

      text-transform: initial;
    }
  }

  &__tx {
    @media only screen and (max-width: $school-desktop) {
      text-align: center;
    }
  }

  &__i-wrap {
    position: relative;

    max-width: 570px;

    @media only screen and (max-width: $school-desktop) {
      margin: 0 auto;
    }
  }

  &__circle {
    position: absolute;
    top: -59px;
    right: 150px;

    display: block;

    width: 176px;
    height: 176px;

    object-fit: contain;

    @media only screen and (max-width: $school-desktop) {
      top: -48px;
    }

    @include media-down(tablet) {
      top: -32px;
      right: 110px;

      width: 107px;
      height: 107px;
    }
  }

  &__card {
    position: relative;

    border: 2px solid $black;
    border-radius: 20px;

    overflow: hidden;

    @include media-down(tablet) {
      margin: 0 -8px;
    }
  }

  &__name {
    padding: 8px 15px 11px;

    border-bottom: 2px solid $black;

    background: #fbd277;

    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    color: $black;
  }

  &__img {
    display: block;

    max-width: 570px;
    width: 100%;

    object-fit: contain;
  }
}
</style>
